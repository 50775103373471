import React, { useState, Suspense, useEffect, useMemo } from "react";
import styles from "./Sidebar.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSpring, animated } from "@react-spring/web";
import Chroma from "chroma-js";
import { useActualPlaceholder } from "Utils/hooks";
import { useDisplayedPlaceholders } from "Utils/hooks/placeholders";

const Carousel = React.lazy(() => import("./Carousel"));
const allowedTypes = ["collection", "pointOfInterest"];

function Slider({ isBottomBar, bottomBarRef }) {
  const [open, toggle] = useState(true);
  const { activePlaceholderId, set } = useActualPlaceholder();
  const { primary } = window?.__CONFIG__?.theme ?? {};
  const { placeholders, parent, clearFilters } = useDisplayedPlaceholders();
  const [ready,setReady] = useState(false);

  const bottomBarHeight =
    bottomBarRef?.current?.getBoundingClientRect().height || 0;

  const closeHeight = (bottomBarHeight / window.innerHeight) * 100;

  const openHeight = closeHeight - 15;

  const show = useMemo(
    () =>
      [...placeholders, parent]?.filter(
        (p) => p?.target?.id ?? "none"
        // && allowedTypes.includes(p?.target?.type)
      ).length >= 1,
    [placeholders, parent]
  );
  useEffect(() => {
    activePlaceholderId && toggle(show);
  }, [activePlaceholderId, show]);

  useEffect(() => {
    !open && set(null);
    // !open && clearFilters();
  }, [open]);

  const config = { mass: 0.5, tension: 230, friction: 25 };

  const transi_container = useSpring({
    to: {
      bottom: show ? (open ? `${closeHeight}vh` : `${openHeight}vh`) : "-30vh",
    },
    from: {
      bottom: "-30vh",
      // background: "#DADFE1",
    },
    onRest: () => {
      setReady(true);
    },
    config: config,
  });

  const transi_content = useSpring({
    from: {
      opacity: 0,
      background: Chroma(primary?.main ?? "#E0C03D")
        .darken(0.2)
        .alpha(1)
        .css(),
    },
    opacity: open ? 1 : 0,
    // marginTop: open ? '100%' : '50%',
    config: config,
    background: open
      ? Chroma("#dadfe1").darken(1).alpha(1).css()
      : Chroma(primary?.main ?? "#E0C03D")
          .darken(0.2)
          .alpha(1)
          .css(),
  });

  const Afab = animated(Fab); //rend le composant Fab (floating button) de materialUI animable
  return placeholders?.length >= 1 ? (
    <animated.div
      // className={styles.container}
      style={{
        ...transi_container,
        position: "fixed",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "fit-content()",
        zIndex: 10,
        background: "rgba(255,255,255,.9)",
        backdropFilter: "blur(2px)",
      }}
    >
      <Afab
        disabled={!show}
        style={{
          background: show ? transi_content.background : "grey",
          color: "white",
          position: "absolute",
          top: -50,
          left: "calc(50vw-16px)",
        }}
        onClick={() => {
          toggle(!open);
        }}
      >
        {open ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowUpIcon
            style={{ color: window.__CONFIG__.theme.onPrimaryColor ?? "white" }}
          />
        )}
      </Afab>

      {
        <Suspense
          fallback={
            <div
              className={styles.content}
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <animated.div
            style={{ opacity: transi_content.opacity }}
            className={styles.content}
          >
            <Carousel ready={ready} openSidebar={(bool) => toggle(bool)} />
          </animated.div>
        </Suspense>
      }
    </animated.div>
  ) : null;
}

export default React.memo(Slider);
//export default Sidebar
