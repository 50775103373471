import React, { useState } from "react";
import { Html } from "@react-three/drei";
import { Model, Model2D } from "Components/Threejs/3DComponents";
import EditorMarker from "Components/Threejs/3DComponents/Placeholders/EditorMarker";
import { Button, Box } from "@mui/material";
import { useStore } from "Store";
import { useLayers } from "Utils/hooks";
import {
  useDisplayedPlaceholders,
  useActualPlaceholder,
} from "Utils/hooks/placeholders";

import { useNavigate } from "react-router-dom";
import MapCanvas from "../3DComponents/Map/MapCanvas";

const EditorMap = () => {
  const { layers } = useLayers();
  return layers?.length ? (
    <MapCanvas controlProps={{ freeControls: true }}>
      <Models />
    </MapCanvas>
  ) : (
    <CreateButton />
  );
};

export default EditorMap;

const Models = () => {
  const { layerHeight, data } = useStore();
  const { placeholders } = useDisplayedPlaceholders({ editorMap: true });
  const { activePlaceholderId, setPosition } = useActualPlaceholder();
  const [target, setTarget] = useState(null);
  const [active, setActive] = useState(false);

  const handleMapClick = (e) => {
    e.stopPropagation();
    const { x, y, z } = e.point;
    setTarget([x, z, layerHeight]);
  };

  const handleDoubleClick = (e) => {
    e.stopPropagation();
    const { x, y, z } = e.point;
    setTarget([x, z, layerHeight]);
    // activePlaceholderId && setPosition({ x, y: 0, z });
  };

  return (
    <>
      {data.is3DFilesType ? (
        <Model onPointerDown={handleMapClick} />
      ) : (
        <Model2D
          onClick={handleDoubleClick}
          onPointerMove={handleMapClick}
          editorMap
        />
      )}

      {placeholders?.map((ph, index) => (
        <EditorMarker
          key={ph.id}
          data={ph}
          pos={ph.position}
          target={target}
          getMapPosition={(value) => setActive(value)}
          resetTarget={() => setTarget(null)}
          editorMap
        />
      ))}
    </>
  );
};

const CreateButton = () => {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent={"center"}
      alignItems="center"
    >
      <h3>Oups, il n'y a pas de données pour ce plan</h3>
      <Button variant="outlined" onClick={() => navigate("/configuration")}>
        Créer
      </Button>
    </Box>
  );
};
