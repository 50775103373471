import React, { useState, useEffect, useRef } from "react";
import PlaceHolder from "./2Dmodel";
import { useSpring, animated } from "@react-spring/three";
import { useActualPlaceholder } from "Utils/hooks";
import { useStore } from "Store";
import { useBounds } from "Utils/hooks/bounds";
import EditorLabel from "../EditorLabel";

export default function EditablePlaceHolder({
  target,
  resetTarget,
  getMapPosition,
  data,
  ...props
}) {
  const { position, marker, parentId } = data ?? {};
  const phRef = useRef();
  const setStoreValue = useStore((s) => s.setValue);
  const { x, y, z } = position;

  const [active, setActive] = useState(false);
  const [movable, setMovable] = useState(false);
  const { set, activePlaceholderId, setPosition } =
    useActualPlaceholder(parentId);

  const bounds = useBounds();

  const markerScale = marker?.scale ?? 1;

  const [{ xyz }, api] = useSpring(
    () => ({
      to: { xyz: [x, 0, z] },
      from: { xyz: [x, y, z] },
      config: { mass: 0.5, tension: 120, friction: 15 },
      delay: 200,
    }),
    [position]
  );

  const { scale } = useSpring({
    scale: active ? 1.5 * markerScale : markerScale,
  });

  useEffect(() => {
    movable && target && api.start({ xyz: [target[0], 0, target[1] ]});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  useEffect(() => {
    const [x, y, z] = xyz.get();
    setStoreValue("editorMapPan", !movable);
    !movable && y === 0 && setPosition({ x, y, z });
  }, [movable]);

  useEffect(() => {
    setActive(activePlaceholderId === data?.id);
  }, [activePlaceholderId]);

  useEffect(() => {
    //set(active ? data?.id : null);
    setMovable(false);
    getMapPosition(active);
    active && bounds?.refresh(phRef.current).fit();
  }, [active]);

  const handleClick = (e, ref) => {
    e.stopPropagation();
    set(active ? null : data?.id);
  };

  const handleActivation = e => {
    e.stopPropagation();
    active && setMovable((m) => !m);
  }

  const AnimatedPlaceholder = animated(PlaceHolder);

  return (
    <>
    <AnimatedPlaceholder
      position={xyz}
      ref={phRef}
      onPointerDown={handleActivation}
      onDoubleClick={handleClick}
      color={movable ? "orange" : marker?.color}
      icon={marker?.icon}
      iconColor={marker?.iconColor}
      scale={scale}
      opacity={1}
      {...props}
    />
    {active && <EditorLabel position={xyz} active={movable} onClick={handleActivation} />}
    </>
  );
}
