import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import { ALL_COLLECTIONS, ALL_POIS, GET_POI } from "Config/API/queries";
import React, { useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useActualLayer, useActualPlaceholder } from "Utils/hooks";
import { useTranslation } from "react-i18next";

const TargetConfig = ({ data }) => {
  const pois = useQuery(ALL_POIS);
  const [getPoi, singlePoi] = useLazyQuery(GET_POI);
  const collections = useQuery(ALL_COLLECTIONS);
  const { setTarget } = useActualPlaceholder(data?.parentId);
  const { type, id, contextCollection } = data?.target ?? {};
  const { otherLayers } = useActualLayer();
  const { t } = useTranslation();

  const handleTypeChange = (e) => {
    const type = e?.target?.value;
    type === "none" ? setTarget(null) : setTarget({ type: e?.target?.value });
  };

  const handleTargetIdChange = (e) => {
    const newId = e?.target?.value;
    setTarget({
      ...data?.target,
      id: newId === "none" ? null : newId,
      contextCollection: null,
    });
  };

  const handleContextCollectionChange = (e) => {
    const newId = e?.target?.value;
    setTarget({
      ...data?.target,
      contextCollection: newId === "none" ? null : newId,
    });
  };

  useEffect(() => {
    type === "pointOfInterest" &&
      id &&
      getPoi({
        variables: {
          uuid: id,
          iso: "fr",
        },
      });
  }, [type, id]);

  return (
    <>
      <Box my={2}>
        <InputLabel shrink>Type de cible</InputLabel>
        <Select value={type ?? "none"} onChange={handleTypeChange}>
          <MenuItem value="none">{t("/editor.gui.none")}</MenuItem>
          <MenuItem value="pointOfInterest">{t("/editor.gui.poi")}</MenuItem>
          <MenuItem value="collection">{t("/editor.gui.collection")}</MenuItem>
          <MenuItem value="layerLink">{t("/editor.gui.layer")}</MenuItem>
          <MenuItem value="mapLink">{t("/editor.gui.maplink")}</MenuItem>
        </Select>
      </Box>
      {type === "pointOfInterest" && !pois?.loading && (
        <Box id="poi">
          <InputLabel shrink>{t("/editor.gui.poi")}</InputLabel>
          <Select value={id ?? "none"} onChange={handleTargetIdChange} sx={{ maxWidth: 300 }}>
            <MenuItem value="none">{t("/editor.gui.none")}</MenuItem>
            {[...pois?.data?.pointsOfInterest?.items]
              ?.sort((a, b) => {
                var textA = a.i18n?.title?.value?.text;
                var textB = b.i18n?.title?.value?.text;
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
              .map((p) => (
                <MenuItem key={p?.id} value={p?.id}>
                  {p?.i18n?.title?.value?.text}
                </MenuItem>
              ))}
          </Select>
        </Box>
      )}
      {type === "pointOfInterest" &&
        id &&
        singlePoi?.data?.pointOfInterest?.collections?.length > 0 && (
          <Box my={2} id="contextCollection">
            <InputLabel shrink>{t("/editor.gui.collection_link")}</InputLabel>
            <Select
              onChange={handleContextCollectionChange}
              value={contextCollection ?? "none"}
            >
              <MenuItem value="none">{t("/editor.gui.none")}</MenuItem>
              {[...singlePoi?.data?.pointOfInterest?.collections]
                ?.sort((a, b) => {
                  var textA = a.i18n?.title?.value?.text;
                  var textB = b.i18n?.title?.value?.text;
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
                })
                .map((c) => (
                  <MenuItem key={c?.id} value={c?.id}>
                    {c?.i18n?.title?.value?.text}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        )}
      {type === "collection" && !collections?.loading && (
        <Box id="collection">
          <InputLabel shrink>{t("/editor.gui.collection")}</InputLabel>
          <Select sx={{ maxWidth: 300 }} value={id ?? "none"} onChange={handleTargetIdChange}>
            <MenuItem value="none">{t("/editor.gui.none")}</MenuItem>
            {[...collections?.data?.collections?.items]
              ?.sort((a, b) => {
                var textA = a.i18n?.title?.value?.text;
                var textB = b.i18n?.title?.value?.text;
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
              .map((p) => (
                <MenuItem key={p?.id} value={p?.id}>
                  {p?.i18n?.title?.value?.text}
                </MenuItem>
              ))}
          </Select>
        </Box>
      )}
      {type === "layerLink" && (
        <Box my={2} id="layerLink">
          <InputLabel shrink>{t("/editor.gui.level_select")}</InputLabel>

          <Select sx={{ maxWidth: 300 }} value={id ?? "none"} onChange={handleTargetIdChange}>
            <MenuItem value="none">{t("/editor.gui.none")}</MenuItem>
            {otherLayers?.map((f) => (
              <MenuItem key={f?.id} value={f?.id}>
                {f?.i18n?.title?.value?.text ?? f?.title?.[0]?.value?.text}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
    </>
  );
};

export default TargetConfig;
